.comunidad-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #002333;
 
    margin: 120px 0px -48px 0px;
}

.comunidad-container .screen-heading {
    color: aliceblue;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.video-container iframe {
  margin: 10px 0;
  max-width: 100%; /* Asegura que el ancho máximo del iframe sea el 100% */
}

@media only screen and (max-width: 768px) {
  /* Estilos específicos para pantallas con un ancho máximo de 768px */
  .video-container iframe {
    width: 90%; /* Ajusta el ancho del iframe según tus necesidades */
    height: 100%; /* Ajusta el alto del iframe según tus necesidades */

  }
}


