.resume-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: fit-content;
  margin: 120px 0;
}

.resume-card {
  display: flex;
  height: 360px;
  width: 1000px;
}

.resume-bullets {
  height: 100%;
  box-shadow: 15px 0 9px -15px #1f2235;
  width: 320px;
}

.resume-bullets-details {
  flex-grow: 1;
  width: 600px;
  overflow: hidden;
  padding: 0 0 0 70px;
}

.experience-description {
  margin: 10px 0 0 0;
  text-align: justify;
  max-width: 100%;
}
.resume-details-carousal {
  transition: transform 1s ease-out;
}
.resume-details-carousal{
    transition: transform 1s ease-out;
}
.bullet-container{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.bullets{
    width: 80%;
    position: relative;
    z-index: 2;
}
.bullet{
    display: flex;
    align-items: center;
    background-color: #1f2235;
    height: 40px;
    margin: 15px 0;
    padding: 0 8px;
    border-radius: 20px;
    width: 30px;
    /* transition: width 0.6s ease; */
    cursor: pointer;
}
.bullet-label{
    font-size: 14px;
    white-space: nowrap;
    font-family: "Poppins SemiBold";
}

.bullet-logo{
    height: 16px;
    margin: 0 30px 0 0;
}
.bullet-icons{
    width: 34px;
    height: 100%;
    z-index: 1;
    background-color: #1f2235;
    position: absolute;
}

.selected-bullet {
    align-content: center;
    align-items: center;
    background-color: #1f2235;
    /* border-radius: 0px 20px 20px 0px; */
    color: #fff;
    display: flex;
    height: 33px;
    justify-content: inherit;
    margin: 15px 1px;
    padding: 0 8px;
    transition: width .6s ease;
    width: 125%;
}
.resume-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: revert-layer;
    height: 360px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.message {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.resume-heading{
    display: flex;
    flex-direction: column;
}

.resume-main-heading{
    position: relative;
    display: flex;
    justify-content: space-between;
}

.resume-main-heading span{
    background-color: var(--dark-orange);
    padding: 4px 12px;
    font-size: 14px;
    border-radius: 14px;
    color: white;
}
.resume-sub-heading span{ 
    background-color:#00000082;
    padding: 4px 12px;
    font-size: 12px;
    border-radius: 5px;
    color: white;
}

.resume-sub-heading span:hover{
    background-color: var(--dark-orange);
    transition: 0.5s ease;
}
.heading-bullet {
    position: absolute;
    left: -30px;
    height: 15px;
    width: 15px;
    top: 5px;
    background-color: var(--dark-orange);
    border-radius: 50%;
}

.resume-sub-heading{
    font-size: 14px;
    font-family: "Poppin SemiBold";
    color:#1f2235;
    margin:10px 0 0 0;

}

.resume-heading-description{
    font-size: 12px;
    text-align: justify;
}

.heading-date{
    background-color: var(--dark-orange);
    padding: 4px 12px;
    font-size: 14px;
    border-radius: 14px;
    color: white;
}
.skill-parent{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
}
.programming-skills-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.skill-percentage{
    position: relative;
    height: 15px;
    width: 70%;
    background-color: var(--dark-orange);
}

.skill-percentage .active-percentage-bar{
    height: 15px;
    transition: 0.8s ease;
    position: absolute;
    background-color: #1f2235;
}

.skill-parent span{
    font-family: "Poppins SemiBold";
    font-size: 16px;
    color: var(--dark-orange);
}


@media only screen and (max-width: 1110px){
    .resume-card{
        width: 880px;
    }
    .resume-bullets-details{
        padding: 0 0 0 70px;
    }
}
@media only screen and (max-width: 1023px){
    .resume-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bullets{
        width: 90%;
    }
    .resume-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin: 30px 0;
        align-items: center;
        width: 90%;
    }

    .resume-bullets-details{
        height: 360px;
        width: fit-content;
        padding: 0
    }

    .resume-bullets{
        width: 100%;
        margin: 30px 0;
    }
    .selected-bullet{
        width: 100%;

    }
}