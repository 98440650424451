.header-container {
  display: flex;
  justify-content: center;
  height: 140px;
  color: var(--white);
  cursor: pointer;
}

.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 70%;
  color: var(--white);
}

.header-logo {
  font-size: 50px;
  font-family: "Poppins ExtraBold";
  color: var(--white);
}

.header-options {
  display: flex;
  align-items: center;
}

.header-option {
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.header-hamburger {
  display: none;
  color: var(--white);
}

.header-option-separator {
  margin: 0 50px 0 0;
  color: var(--white);
}

.selected-header-option {
  color: #ff5823;
  cursor: pointer;
}

.Traductor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: var(--white);
  position: relative;
}

.Traductor li {
  display: flex;
  align-items: center;
  list-style: none;
}

.Traductor li img {
  margin-right: 10px;
}

.Traductor li a {
  color: var(--white);
  font-size: 13px;
  font-family: "Poppins Light";
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  margin-right: 20px;
}

/* Estilos para cuando el cursor está sobre el enlace */
.Traductor li a:hover {
  color: #ff5823;
}

/* Estilos para el enlace de la página actual */
.Traductor li a.current-page {
  color: #ffff00; /* Cambia este color según tus preferencias para resaltar el enlace de la página actual */
}

/* Estilos para la imagen cuando el cursor está sobre ella */
.Traductor li img:hover + a {
  color: #ff5823;
}

/* Estilos para el icono de idioma */
.Traductor .fa-language {
  font-size: 24px;
  cursor: pointer;
}

/* Estilos para el menú desplegable */
.menu-traductor {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  list-style: none;
  margin: 0;
}

.menu-traductor li {
  margin-bottom: 10px;
}

.menu-traductor a {
  color: var(--white);
  font-size: 13px;
  font-family: "Poppins Light";
  font-weight: 600;
  text-decoration: none;
}

.menu-traductor a:hover {
  color: #ff5823;
}

/* Mostrar el menú desplegable al pasar el cursor sobre el contenedor */
.Traductor:hover .menu-traductor {
  display: block;
}


@media only screen and (max-width: 1110px) {
  .header-parent {
    width: 80%;
  }
}

@media only screen and (max-width: 970px) {
  .header-hamburger {
    display: block;
  }
  .header-parent {
    width: 100%;
    padding: 0 40px;
    position: relative;
  }

  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100%;
    top: 0px;
    height: 100vh;
    width: 100%;
    justify-content: space-around;
    background-color: #1f2235;
    z-index: 1000;
    opacity: 0.8;
    transition: all 0.8s ease-out;
  }
  .header-option-separator {
    margin: 0;
  }
  .selected-header-option {
    color: var(--white);
  }
  .header-option {
    color: var(--white);
  }
  .header-hamburger-bars {
    font-size: 30px;
    cursor: pointer;
    margin: 0px 10px;
    color: var(--white);
  }
  .show-hamburger-options {
    left: 0;
    opacity: 0.95;
  }

  .header-option-separator {
    margin: 0 10px 0 0;
    color: var(--white);
}
.Traductor {
  display: contents;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: var(--white);
  position: relative;
}
.Traductor.active .menu-traductor {
  display: block;
}
.menu-traductor active {
  display: flex;
}

}

@media only screen and (max-width: 520px) {
  .header-logo {
    font-size: 25px;  

  }
  .fa-language{
    display: none;
  }
  .menu-traductor {
    display: block;
    position: static;
    background-color: transparent;
    top: 100%;
  }
  .menu-traductor li {
  padding: auto;
  font-size: medium;

  }
  .Traductor li  a {
    color: var(--white);
    font-size: 17px;
    padding: 20px;
    font-family: "Poppins Light";
    font-weight: 600;
    text-decoration: none;
    
  }
  .header-options{
    justify-content:space-evenly;
  }
}